import React from 'react';

import Container from 'react-bootstrap/Container';

function ActionSection() {
  return (
    <div className="site-section" id="gray-background">
      <Container className="centered">
        <br />
        <h1 className="heading">Make a meaningful connection</h1>
        <br />
        <h3>It's easy and free!</h3>
        <br />
        <a href="signup" className="dark-button">
          Sign Me Up
        </a>
      </Container>

    </div>
  );
}
export default ActionSection;
